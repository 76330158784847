import React from "react";
import Layout from "../components/Layout";
import AboutHeader from "../components/AboutHeader/AboutHeader";
import AboutDescription from "../components/AboutDescription/AboutDescription";

const About = () => {
  return (
    <Layout title="" description="">
        <AboutHeader/>
        <AboutDescription/>
    </Layout>
  );
};

export default About;