import React, { useState } from "react";
import title from "../../images/about-title.png";
import "./AboutHeader.scss";

const AboutHeader = (props) => {
  return (
    <div className="hero about-hero">
		  <div className="hero-text">
        <img src={title} alt="For the Love of Baloney"/>
      </div>
		</div>
    
  );
};

export default AboutHeader;
