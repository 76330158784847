import React, { useState } from "react";
import small from "../../images/about-s.jpg";
import large from "../../images/about-m.jpg";
import "./AboutDescription.scss";
import { Row, Col, Container } from "react-bootstrap";

const AboutDescription = () => {
  return (
    <Container className="about-content">
      <Row>
        <Col xs={12} xl={8} sm={12} lg={6} md={6} className="p-0">
          <div className="about-text">
            <p>
              Inspired chefs everywhere are moving bologna from the kid’s
              lunchbox to the family table and creating a whole new generation
              of bologna lovers.
            </p>
            <p>
              Popular restaurants across the country are serving up bologna
              culinary creations that people are standing in line to order.
            </p>
            <p>
              Whether it’s an award-winning Fried Bologna Sandwich in Chicago or
              a Cajun creation in New Orleans, it’s on trend, exciting and just
              down-right…good for the soul.
            </p>
            <p>
              Serve your own surprising twist on an old favorite with Bologna
              Eggs Benedict for breakfast, our signature Fried Bologna Sandwich
              for lunch, or really shake it up with Bologna Tacos for dinner.
            </p>
            <p>
              Check out these and all of our chef-inspired recipes for your next
              meal. Share the love…your family will approve!
            </p>
          </div>
        </Col>
        <Col className="d-none d-lg-block d-md-block d-xl-block largeImg">
          <img className="la" src={large} alt="For the Love of Baloney" />
        </Col>
        <Col className="d-sm-block d-xs-block d-lg-none d-md-none d-xl-none about-pics smallImg">
          <img src={small} alt="For the Love of Baloney" />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutDescription;
